import {
  fields,
  Segments,
  Predefined,
  defaultPredefined,
  State,
  getIncludesGroups,
  getExcludesGroups,
  AgeType,
  getExportType,
  ExportType,
  Demographics,
} from 'modules/audiences/AudienceProvider';
import { EnhancedTreeData, TreeData } from 'utils/segmentsToTreeData';

const { LOCATION_FIELD, AGE_FIELD, GENDER_FIELD, DIRECT_FIELD, PREDEFINED_FIELD, DIRECT_SEGMENT_FIELD } = fields;

//TODO: [OA-1107] Remove redundant Type Defs types
type Segment = Demographics | EnhancedTreeData[] | TreeData[] | Predefined;

export const isAgeRangeEmpty = ({ min, max }: AgeType): boolean => !min && !max;

export const isPredefinedEmpty = ({ rtg, site_category, third_party }: Predefined): boolean =>
  !rtg.length && !site_category.length && !third_party.length;

export const isSegmentEmpty = (type: string, segment: Segment): boolean => {
  switch (type) {
    case 'DEMOGRAPHICS_FIELD_V1':
      return false;
    case PREDEFINED_FIELD:
      return isPredefinedEmpty(segment as Predefined);
    default:
      return (segment as TreeData[]).length === 0;
  }
};

export const areSegmentsEmpty = (segments: Segments): boolean => {
  if (!segments) return true;

  const {
    [LOCATION_FIELD]: locationSegments = [],
    [AGE_FIELD]: ageSegments = [],
    [GENDER_FIELD]: genderSegments = [],
    [DIRECT_FIELD]: firstPartySegments = [],
    [PREDEFINED_FIELD]: predefinedSegments = defaultPredefined,
    [DIRECT_SEGMENT_FIELD]: directProviderSegments = [],
  } = segments;

  return (
    locationSegments.length === 0 &&
    firstPartySegments.length === 0 &&
    directProviderSegments.length === 0 &&
    ageSegments.length === 0 &&
    genderSegments.length === 0 &&
    isPredefinedEmpty(predefinedSegments)
  );
};

export const isGroupEmpty = ({ segments, subgroups }): boolean =>
  areSegmentsEmpty(segments) && subgroups.every(({ segments }) => areSegmentsEmpty(segments));

export const noSegmentsSelected = (state: State): boolean => {
  const includesGroups = getIncludesGroups(state);
  const excludesGroups = getExcludesGroups(state);

  return includesGroups.every(isGroupEmpty) && excludesGroups.every(isGroupEmpty);
};

export const isDirectedSegmentSelected = (state: State): boolean => {
  const includesGroups = getIncludesGroups(state);
  const excludesGroups = getExcludesGroups(state);
  const exportType = getExportType(state);

  const hasDirectSegment = ({ segments, subgroups }) => {
    if (!subgroups || !subgroups.length) {
      const { [DIRECT_SEGMENT_FIELD]: directSegments } = segments;

      return directSegments.length > 0;
    }

    return subgroups.some(hasDirectSegment);
  };

  return exportType === ExportType.BIDSTREAM || exportType === ExportType.BIDSTREAM_PLUS
    ? includesGroups.some(hasDirectSegment)
    : includesGroups.some(hasDirectSegment) || excludesGroups.some(hasDirectSegment);
};

export const omitEmptyGroupsAndSubgroups = groups =>
  groups.reduce((resultGroups, group) => {
    const newGroup = { ...group, subgroups: group.subgroups.filter(({ segments }) => !areSegmentsEmpty(segments)) };

    return isGroupEmpty(newGroup) ? resultGroups : [...resultGroups, newGroup];
  }, []);
