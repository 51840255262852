import { isEqual } from 'lodash';

import { areSegmentsEmpty, getDirectSegments, omitDirectSegments } from 'modules/audiences/utils';

import fields from './fields';
import { MatchingOperator, Action, RelationType, ActionType } from './types';
import {
  State,
  defaultSegments,
  defaultPredefined,
  defaultInitialState,
  defaultShareRow,
  defaultBulkOption,
} from './AudienceProvider';
import {
  getIncludesGroups,
  getExcludesGroups,
  getIncludesField,
  getExcludesField,
  getOpenSubgroup,
  getOpenSubgroupsRelationField,
  getOpenSubgroupsSegments,
  getOpenSubgroupsGroupIndex,
  getOpenSubgroupsSubgroupIndex,
  getAudienceShares,
} from './selectors';

export const audienceReducer = (state: State, action: Action): State => {
  const { type, payload } = action;

  const {
    account_id,
    groupIndex,
    subgroupIndex,
    operator,
    relationField,
    segments,
    isDrawerOpen,
    selectedAge,
    directProvider,
    exportType,
    ctv,
    name,
    description,
    audienceShareFee,
    audienceShareRevenueMethod,
    audienceShareIndex,
    audienceShareRecipients = [],
    audienceShares,
    normalized_definition,
    bulkAction,
    templateName,
    exportTargets,
  } = payload || {};

  const {
    [fields.LOCATION_FIELD]: location,
    [fields.AGE_FIELD]: age,
    [fields.GENDER_FIELD]: gender,
    [fields.DIRECT_FIELD]: firstParty,
    [fields.PREDEFINED_FIELD]: predefined,
    [fields.DIRECT_SEGMENT_FIELD]: directSegment,
  } = segments || {};

  const includes = getIncludesField(state);
  const excludes = getExcludesField(state);

  const includesGroups = getIncludesGroups(state);
  const excludesGroups = getExcludesGroups(state);

  const openSubgroup = getOpenSubgroup(state);

  const map = {
    [RelationType.INCLUDE]: {
      relationObject: includes,
      groups: includesGroups,
    },
    [RelationType.EXCLUDE]: {
      relationObject: excludes,
      groups: excludesGroups,
    },
  };

  const groups = map[relationField || '']?.[fields.GROUPS_FIELD] || [];
  const relationObject = map[relationField || '']?.relationObject || {};

  const openSubgroupRelationField = getOpenSubgroupsRelationField(state);
  const openSubgroupsSegments = getOpenSubgroupsSegments(state);
  const openSubgroupsGroupIndex = getOpenSubgroupsGroupIndex(state);
  const openSubgroupsSubgroupIndex = getOpenSubgroupsSubgroupIndex(state);
  const _audienceShares = getAudienceShares(state);

  switch (type) {
    case ActionType.SET_ACCOUNT_ID:
      return {
        ...state,
        [fields.ACCOUNT_ID_FIELD]: account_id ?? null,
      };

    case ActionType.SET_INCLUDES_OPERATOR:
      return { ...state, [fields.INCLUDE_FIELD]: { ...includes, [fields.OPERATOR_FIELD]: operator } };

    case ActionType.SET_GROUP_OPERATOR:
      return {
        ...state,
        [relationField as string]: {
          ...relationObject,
          [fields.GROUPS_FIELD]: groups.map(group =>
            group.index !== groupIndex
              ? group
              : { ...group, [fields.OPERATOR_FIELD]: operator || MatchingOperator.INTERSECTION },
          ),
        },
      };

    case ActionType.ADD_GROUP:
      return {
        ...state,
        [relationField as string]: {
          ...relationObject,
          [fields.GROUPS_FIELD]: [
            ...groups,
            {
              [fields.OPERATOR_FIELD]: MatchingOperator.INTERSECTION,
              [fields.INDEX_FIELD]: groupIndex,
              [fields.SUBGROUPS_FIELD]: [],
              [fields.SEGMENTS_FIELD]: defaultSegments,
            },
          ],
        },
      };

    case ActionType.ADD_SUBGROUP:
      return {
        ...state,
        [relationField as string]: {
          ...relationObject,
          [fields.GROUPS_FIELD]: groups.map(group =>
            group.index !== groupIndex
              ? group
              : {
                  ...group,
                  [fields.SEGMENTS_FIELD]: defaultSegments,
                  [fields.SUBGROUPS_FIELD]: areSegmentsEmpty(group[fields.SEGMENTS_FIELD])
                    ? [
                        ...group[fields.SUBGROUPS_FIELD],
                        { [fields.INDEX_FIELD]: subgroupIndex, [fields.SEGMENTS_FIELD]: defaultSegments },
                      ]
                    : [
                        { [fields.INDEX_FIELD]: 1, [fields.SEGMENTS_FIELD]: group[fields.SEGMENTS_FIELD] },
                        { [fields.INDEX_FIELD]: 2, [fields.SEGMENTS_FIELD]: defaultSegments },
                      ],
                },
          ),
        },
      };

    case ActionType.REMOVE_GROUP:
      return {
        ...state,
        [relationField as string]: {
          ...relationObject,
          [fields.GROUPS_FIELD]: groups.filter(({ index }) => index !== groupIndex),
        },
      };

    case ActionType.REMOVE_SUBGROUP:
      return {
        ...state,
        [relationField as string]: {
          ...relationObject,
          [fields.GROUPS_FIELD]: groups.map(group =>
            group.index !== groupIndex
              ? group
              : {
                  ...group,
                  [fields.SUBGROUPS_FIELD]: group[fields.SUBGROUPS_FIELD].filter(
                    ({ index }) => index !== subgroupIndex,
                  ),
                },
          ),
        },
      };

    case ActionType.SET_OPEN_SUBGROUP:
      return {
        ...state,
        [fields.OPEN_SUBGROUP_FIELD]: {
          [fields.GROUP_INDEX_FIELD]: groupIndex || 0,
          [fields.SUBGROUP_INDEX_FIELD]: subgroupIndex || 0,
          [fields.RELATION_FIELD]: relationField || RelationType.INCLUDE,
          [fields.SEGMENTS_FIELD]: segments || defaultSegments,
        },
      };

    case ActionType.SAVE_SEGMENT:
      return {
        ...state,
        [openSubgroupRelationField]: {
          ...state[openSubgroupRelationField],
          [fields.GROUPS_FIELD]: state[openSubgroupRelationField][fields.GROUPS_FIELD].map(group =>
            group.index !== openSubgroupsGroupIndex
              ? group
              : openSubgroupsSubgroupIndex === 0
              ? { ...group, [fields.SEGMENTS_FIELD]: openSubgroupsSegments }
              : {
                  ...group,
                  [fields.SUBGROUPS_FIELD]: group[fields.SUBGROUPS_FIELD].map(subgroup =>
                    subgroup.index !== openSubgroupsSubgroupIndex
                      ? subgroup
                      : {
                          ...subgroup,
                          [fields.SEGMENTS_FIELD]: openSubgroupsSegments,
                        },
                  ),
                },
          ),
        },
      };

    case ActionType.SET_LOCATION:
      return {
        ...state,
        [fields.OPEN_SUBGROUP_FIELD]: {
          ...openSubgroup,
          [fields.SEGMENTS_FIELD]: {
            ...openSubgroup[fields.SEGMENTS_FIELD],
            [fields.LOCATION_FIELD]: location || [],
          },
        },
      };

    case ActionType.SET_DEMOGRAPHIC_AGE:
      return {
        ...state,
        [fields.OPEN_SUBGROUP_FIELD]: {
          ...openSubgroup,
          [fields.SEGMENTS_FIELD]: {
            ...openSubgroup[fields.SEGMENTS_FIELD],
            [fields.AGE_FIELD]: age || [],
          },
        },
      };

    case ActionType.SET_DEMOGRAPHIC_GENDER:
      return {
        ...state,
        [fields.OPEN_SUBGROUP_FIELD]: {
          ...openSubgroup,
          [fields.SEGMENTS_FIELD]: {
            ...openSubgroup[fields.SEGMENTS_FIELD],
            [fields.GENDER_FIELD]: gender || [],
          },
        },
      };

    case ActionType.SET_DIRECT_SEGMENT:
      return {
        ...state,
        [fields.OPEN_SUBGROUP_FIELD]: {
          ...openSubgroup,
          [fields.SEGMENTS_FIELD]: {
            ...openSubgroup[fields.SEGMENTS_FIELD],
            [fields.DIRECT_SEGMENT_FIELD]: directSegment || [],
          },
        },
      };

    case ActionType.SET_IS_DRAWER_OPEN:
      return {
        ...state,
        [fields.IS_DRAWER_OPEN_FIELD]: isDrawerOpen || false,
      };

    case ActionType.SET_SELECTED_AGE:
      return {
        ...state,
        [fields.SELECTED_AGE]: selectedAge,
      };

    case ActionType.SET_PREDEFINED:
      return {
        ...state,
        [fields.OPEN_SUBGROUP_FIELD]: {
          ...openSubgroup,
          [fields.SEGMENTS_FIELD]: {
            ...openSubgroup[fields.SEGMENTS_FIELD],
            [fields.PREDEFINED_FIELD]: predefined || defaultPredefined,
          },
        },
      };

    case ActionType.SET_DIRECT_PROVIDER:
      return {
        ...state,
        [fields.INCLUDE_FIELD]: {
          [fields.OPERATOR_FIELD]: MatchingOperator.INTERSECTION,
          [fields.GROUPS_FIELD]: [
            {
              [fields.INDEX_FIELD]: 1,
              [fields.SUBGROUPS_FIELD]: [],
              [fields.OPERATOR_FIELD]: MatchingOperator.INTERSECTION,
              [fields.SEGMENTS_FIELD]: defaultSegments,
            },
          ],
        },
        [fields.EXCLUDE_FIELD]: {
          [fields.OPERATOR_FIELD]: MatchingOperator.INTERSECTION,
          [fields.GROUPS_FIELD]: [
            {
              [fields.INDEX_FIELD]: 1,
              [fields.SUBGROUPS_FIELD]: [],
              [fields.OPERATOR_FIELD]: MatchingOperator.INTERSECTION,
              [fields.SEGMENTS_FIELD]: defaultSegments,
            },
          ],
        },
        [fields.DIRECT_PROVIDER_FIELD]: directProvider || undefined,
        [fields.OPEN_SUBGROUP_FIELD]: {
          [fields.GROUP_INDEX_FIELD]: groupIndex || 0,
          [fields.SUBGROUP_INDEX_FIELD]: subgroupIndex || 0,
          [fields.RELATION_FIELD]: relationField || RelationType.INCLUDE,
          [fields.SEGMENTS_FIELD]: segments || defaultSegments,
        },
        [fields.CTV_FIELD]: false,
      };

    case ActionType.SET_EXPORT_TYPE:
      return {
        ...state,
        [fields.EXPORT_TYPE_FIELD]: exportType,
        [fields.CTV_FIELD]: false,
      };

    case ActionType.SET_CTV:
      return {
        ...state,
        [fields.CTV_FIELD]: ctv || false,
      };

    case ActionType.SET_NAME:
      return {
        ...state,
        [fields.NAME_FIELD]: name || '',
      };

    case ActionType.SET_DESCRIPTION:
      return {
        ...state,
        [fields.DESCRIPTION_FIELD]: description || '',
      };

    case ActionType.SET_AUDIENCE_SHARES_FIELD:
      return {
        ...state,
        [fields.AUDIENCE_SHARES_FIELD]: audienceShares || _audienceShares || [],
      };

    case ActionType.SET_AUDIENCE_SHARE_FEE:
      return {
        ...state,
        [fields.AUDIENCE_SHARES_FIELD]: _audienceShares.map(x =>
          _audienceShares.indexOf(x) === audienceShareIndex ? { ...x, fee: audienceShareFee || null } : x,
        ),
      };

    case ActionType.SET_AUDIENCE_SHARE_REVENUE_METHOD:
      return {
        ...state,
        [fields.AUDIENCE_SHARES_FIELD]: _audienceShares.map(x =>
          _audienceShares.indexOf(x) === audienceShareIndex
            ? { ...x, revenueMethod: audienceShareRevenueMethod || null }
            : x,
        ),
      };

    case ActionType.REMOVE_DEFAULT_AUDIENCE_SHARE:
      return {
        ...state,
        [fields.AUDIENCE_SHARES_FIELD]: _audienceShares.filter(x => !isEqual(x, defaultShareRow)),
      };

    case ActionType.ADD_DEFAULT_AUDIENCE_SHARE:
      return {
        ...state,
        [fields.AUDIENCE_SHARES_FIELD]: [..._audienceShares, defaultShareRow],
      };

    case ActionType.REMOVE_AUDIENCE_SHARE_ROW:
      return {
        ...state,
        [fields.AUDIENCE_SHARES_FIELD]: _audienceShares.filter(x => _audienceShares.indexOf(x) !== audienceShareIndex),
      };

    case ActionType.SET_AUDIENCE_SHARE_RECIPIENTS:
      return {
        ...state,
        [fields.AUDIENCE_SHARES_FIELD]: [
          ..._audienceShares.map(x =>
            _audienceShares.indexOf(x) === audienceShareIndex ? { ...x, recipients: audienceShareRecipients } : x,
          ),
        ],
      };

    case ActionType.RESET_AUDIENCE:
      return {
        ...defaultInitialState,
      };

    case ActionType.SET_FIRST_PARTY:
      return {
        ...state,
        [fields.OPEN_SUBGROUP_FIELD]: {
          ...openSubgroup,
          [fields.SEGMENTS_FIELD]: {
            ...openSubgroup[fields.SEGMENTS_FIELD],
            [fields.DIRECT_FIELD]: firstParty || [],
          },
        },
      };
    case ActionType.SET_BULK_ACTION_FIELD:
      return { ...state, [fields.BULK_ACTION_FIELD]: bulkAction };
    case ActionType.RESET_BULK_ACTION_FIELD:
      return { ...state, [fields.BULK_ACTION_FIELD]: defaultBulkOption };
    case ActionType.SET_AUDIENCE:
      return {
        ...state,
        ...action.payload,
        [fields.INCLUDE_FIELD]: normalized_definition?.includes
          ? {
              ...relationObject,
              [fields.OPERATOR_FIELD]: normalized_definition?.includes.operator,
              [fields.GROUPS_FIELD]: normalized_definition?.includes.groups.map((group, groupIndex) => ({
                [fields.OPERATOR_FIELD]: group.operator || MatchingOperator.INTERSECTION,
                [fields.INDEX_FIELD]: groupIndex + 1,
                [fields.SUBGROUPS_FIELD]:
                  group.groups?.map((subgroup, subgroupIndex) => ({
                    [fields.INDEX_FIELD]: subgroupIndex + 1,
                    [fields.SEGMENTS_FIELD]: {
                      [fields.LOCATION_FIELD]: subgroup[fields.LOCATION_FIELD]?.segments || [],
                      [fields.AGE_FIELD]: subgroup[fields.AGE_FIELD]?.segments || [],
                      [fields.GENDER_FIELD]: subgroup[fields.GENDER_FIELD]?.segments || [],
                      [fields.DIRECT_FIELD]: omitDirectSegments(
                        subgroup[fields.DIRECT_FIELD]?.segments,
                        action?.payload?.providerValues,
                      ),
                      [fields.DIRECT_SEGMENT_FIELD]: getDirectSegments(
                        subgroup[fields.DIRECT_FIELD]?.segments,
                        action?.payload?.providerValues,
                      ),
                      [fields.PREDEFINED_FIELD]: subgroup[fields.PREDEFINED_FIELD]?.segments.length
                        ? {
                            [fields.RTG_FIELD]: subgroup[fields.PREDEFINED_FIELD].segments.filter(
                              ({ type }) => type === 'rtg',
                            ),
                            [fields.THIRD_PARTY_FIELD]: subgroup[fields.PREDEFINED_FIELD].segments.filter(
                              ({ type }) => type === '3p',
                            ),
                            [fields.SITE_CATEGORY_FIELD]: subgroup[fields.PREDEFINED_FIELD].segments.filter(
                              ({ type }) => type === 'iab_site_categories',
                            ),
                          }
                        : defaultPredefined,
                    },
                  })) || [],
                [fields.SEGMENTS_FIELD]: {
                  [fields.LOCATION_FIELD]: group[fields.LOCATION_FIELD]?.segments || [],
                  [fields.AGE_FIELD]: group[fields.AGE_FIELD]?.segments || [],
                  [fields.GENDER_FIELD]: group[fields.GENDER_FIELD]?.segments || [],
                  [fields.DIRECT_FIELD]: omitDirectSegments(
                    group[fields.DIRECT_FIELD]?.segments,
                    action?.payload?.providerValues,
                  ),
                  [fields.DIRECT_SEGMENT_FIELD]: getDirectSegments(
                    group[fields.DIRECT_FIELD]?.segments,
                    action?.payload?.providerValues,
                  ),
                  [fields.PREDEFINED_FIELD]: group[fields.PREDEFINED_FIELD]?.segments.length
                    ? {
                        [fields.RTG_FIELD]: group[fields.PREDEFINED_FIELD].segments.filter(
                          ({ type }) => type === 'rtg',
                        ),
                        [fields.THIRD_PARTY_FIELD]: group[fields.PREDEFINED_FIELD].segments.filter(
                          ({ type }) => type === '3p',
                        ),
                        [fields.SITE_CATEGORY_FIELD]: group[fields.PREDEFINED_FIELD].segments.filter(
                          ({ type }) => type === 'iab_site_categories',
                        ),
                      }
                    : defaultPredefined,
                },
              })),
            }
          : [],
        [fields.EXCLUDE_FIELD]: {
          ...relationObject,
          [fields.GROUPS_FIELD]: normalized_definition?.excludes
            ? normalized_definition?.excludes?.groups.map((group, groupIndex) => ({
                [fields.OPERATOR_FIELD]: group.operator || MatchingOperator.INTERSECTION,
                [fields.INDEX_FIELD]: groupIndex + 1,
                [fields.SUBGROUPS_FIELD]:
                  group.groups?.map((subgroup, subgroupIndex) => ({
                    [fields.INDEX_FIELD]: subgroupIndex + 1,
                    [fields.SEGMENTS_FIELD]: {
                      [fields.LOCATION_FIELD]: subgroup[fields.LOCATION_FIELD]?.segments || [],
                      [fields.AGE_FIELD]: subgroup[fields.AGE_FIELD]?.segments || [],
                      [fields.GENDER_FIELD]: subgroup[fields.GENDER_FIELD]?.segments || [],
                      [fields.DIRECT_FIELD]:
                        omitDirectSegments(subgroup[fields.DIRECT_FIELD]?.segments, action?.payload?.providerValues) ||
                        [],
                      [fields.DIRECT_SEGMENT_FIELD]:
                        getDirectSegments(subgroup[fields.DIRECT_FIELD]?.segments, action?.payload?.providerValues) ||
                        [],
                      [fields.PREDEFINED_FIELD]: subgroup[fields.PREDEFINED_FIELD]?.segments.length
                        ? {
                            [fields.RTG_FIELD]: subgroup[fields.PREDEFINED_FIELD].segments.filter(
                              ({ type }) => type === 'rtg',
                            ),
                            [fields.THIRD_PARTY_FIELD]: subgroup[fields.PREDEFINED_FIELD].segments.filter(
                              ({ type }) => type === '3p',
                            ),
                            [fields.SITE_CATEGORY_FIELD]: subgroup[fields.PREDEFINED_FIELD].segments.filter(
                              ({ type }) => type === 'iab_site_categories',
                            ),
                          }
                        : defaultPredefined,
                    },
                  })) || [],
                [fields.SEGMENTS_FIELD]: {
                  [fields.LOCATION_FIELD]: group[fields.LOCATION_FIELD]?.segments || [],
                  [fields.AGE_FIELD]: group[fields.AGE_FIELD]?.segments || [],
                  [fields.GENDER_FIELD]: group[fields.GENDER_FIELD]?.segments || [],
                  [fields.DIRECT_FIELD]:
                    omitDirectSegments(group[fields.DIRECT_FIELD]?.segments, action?.payload?.providerValues) || [],
                  [fields.DIRECT_SEGMENT_FIELD]:
                    getDirectSegments(group[fields.DIRECT_FIELD]?.segments, action?.payload?.providerValues) || [],
                  [fields.PREDEFINED_FIELD]: group[fields.PREDEFINED_FIELD]?.segments.length
                    ? {
                        [fields.RTG_FIELD]: group[fields.PREDEFINED_FIELD].segments.filter(
                          ({ type }) => type === 'rtg',
                        ),
                        [fields.THIRD_PARTY_FIELD]: group[fields.PREDEFINED_FIELD].segments.filter(
                          ({ type }) => type === '3p',
                        ),
                        [fields.SITE_CATEGORY_FIELD]: group[fields.PREDEFINED_FIELD].segments.filter(
                          ({ type }) => type === 'iab_site_categories',
                        ),
                      }
                    : defaultPredefined,
                },
              }))
            : [],
        },
        [fields.IS_STATE_SYNCED_FIELD]: true,
      };
    case ActionType.SET_AUDIENCE_INSIGHTS:
      return { ...state, [fields.AUDIENCE_INSIGHTS]: action?.payload && action?.payload };

    case ActionType.SET_TEMPLATE_NAME:
      return {
        ...state,
        [fields.TEMPLATE_NAME_FIELD]: templateName || undefined,
      };

    case ActionType.SET_EXPORT_TARGETS:
      return {
        ...state,
        [fields.EXPORT_TARGETS_FIELD]: exportTargets || undefined,
      };

    default:
      return state;
  }
};
