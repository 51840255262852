import React, { useCallback, FC, memo, useMemo } from 'react';
import { Typography, Theme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { isEqual } from 'lodash';
import { CSVLink } from 'react-csv';

import { Paper, Button } from '@openx/components/core';
import { useAudience, getIsDrawerOpen, ActionType, ExportType } from 'modules/audiences/AudienceProvider';
import { EditSegmentDrawer, EstimatedReach } from 'modules/audiences/components';

import { ExcludesPanel, IncludesPanel } from '../BooleanOperators';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    paddingBottom: 0,
  },
  info: {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 30,
    padding: 0,
  },
  message: {
    padding: 0,
  },
  icon: {
    '& svg': {
      width: 15,
      height: 15,
    },
    marginRight: 6.5,
    padding: 0,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  reportButton: {
    marginBottom: 'auto',
  },
  linkColor: {
    color: theme.palette.background.paper,
  },
}));

interface AudienceCriteriaProps {
  disableDownload?: boolean;
  readonly: boolean;
  boxesVisible: boolean | ExportType | undefined;
  isAudienceTypeSelected?: boolean;
  customExportSelected?: boolean;
}

export const AudienceCriteria: FC<AudienceCriteriaProps> = memo(
  ({
    readonly,
    boxesVisible,
    disableDownload = false,
    isAudienceTypeSelected = false,
    customExportSelected = false,
  }): JSX.Element => {
    const { container, info, message, icon, header, reportButton, linkColor } = useStyles();

    const { state, dispatch }: any = useAudience();

    const combineArraysWithKeys = (audiencedata: any, key: string) => {
      const result: unknown[] = [];
      audiencedata?.forEach(data => {
        data.forEach((item): any => {
          const type = typeof item;
          if (type === 'object') {
            item[key] &&
              item[key].forEach((obj): any => {
                result.push({
                  ...obj,
                  providerName: obj.provider?.name,
                  isInclueExclude: key.charAt(0).toUpperCase() + key.slice(1) + 's',
                });
              });
          }
        });
      });
      return result.flat();
    };

    const audienceCriteriaDataExcludes =
      state?.normalized_definition?.excludes?.groups?.length > 0
        ? combineArraysWithKeys(Object.entries(state?.normalized_definition?.excludes?.groups[0]), 'exclude')
        : [];

    const audienceCriteriaDataIncludes =
      state?.normalized_definition?.includes?.groups.length > 0
        ? combineArraysWithKeys(Object.entries(state?.normalized_definition?.includes?.groups[0]), 'include')
        : [];

    const formattedAudienceCriteriaData = [{ ...state?.audience_insights_data, audienceName: state?.name }].concat(
      audienceCriteriaDataExcludes,
      audienceCriteriaDataIncludes,
    );

    const isDrawerOpen = useMemo(() => getIsDrawerOpen(state), [state]);

    const handleApplyDrawerChange = useCallback(() => {
      dispatch({ type: ActionType.SAVE_SEGMENT });
      dispatch({ type: ActionType.SET_IS_DRAWER_OPEN, payload: { isDrawerOpen: false } });
    }, [dispatch]);

    const handleOpenDrawer = useCallback(() => {
      dispatch({ type: ActionType.SET_IS_DRAWER_OPEN, payload: { isDrawerOpen: true } });
    }, [dispatch]);

    const handleCloseDrawer = useCallback(() => {
      dispatch({ type: ActionType.SET_IS_DRAWER_OPEN, payload: { isDrawerOpen: false } });
    }, [dispatch]);

    const audienceReachCsvHeaders = [
      { label: 'Audience Name', key: 'audienceName' },
      { label: 'Total Monthly Active Users', key: 'totalmau' },
      { label: 'Name', key: 'full_name' },
      { label: 'Category', key: 'category' },
      { label: 'Sub Category', key: 'sub_type' },
      { label: 'Description', key: 'description' },
      { label: 'Provider', key: 'providerName' },
      { label: 'Include/Exclude', key: 'isInclueExclude' },
    ];

    return (
      <Paper data-test="audience-criteria" className={container}>
        <div className={header}>
          <Typography variant="h2" data-test="insights-title" gutterBottom>
            Audience Criteria
          </Typography>

          {state.audience_insights_data !== undefined && (
            <Button className={reportButton} disabled={disableDownload}>
              <CSVLink
                headers={audienceReachCsvHeaders}
                data={formattedAudienceCriteriaData !== undefined ? formattedAudienceCriteriaData : []}
                filename={'AudienceCriteriaReport'}
                className={linkColor}
                target="_blank"
              >
                Download
              </CSVLink>
            </Button>
          )}
        </div>

        {!readonly && isAudienceTypeSelected && <EstimatedReach customExportSelected={customExportSelected} />}

        {!isAudienceTypeSelected && !readonly && (
          <Alert className={info} severity="info" classes={{ message, icon }}>
            <Typography variant="body1">Select Audience Options first to begin building your audience</Typography>
          </Alert>
        )}

        {boxesVisible && (
          <>
            <IncludesPanel readonly={readonly} />
            <ExcludesPanel readonly={readonly} />

            {!readonly && (
              <EditSegmentDrawer
                onOpen={handleOpenDrawer}
                onClose={handleCloseDrawer}
                onApply={handleApplyDrawerChange}
                open={isDrawerOpen}
              />
            )}
          </>
        )}
      </Paper>
    );
  },
  (prevProps, nextProps) => isEqual(nextProps, prevProps),
);
