import {
  ExportType,
  State,
  MatchingOperator,
  getName,
  getDescription,
  getExportType,
  getExportTargets,
  getIncludesGroups,
  getExcludesGroups,
  getIncludesMatchingOperator,
  Segments,
  GroupItem,
  getDirectProvider,
  fields,
  NormalizedDefinition,
  NormalizedGroup,
  NormalizedSegments,
  NormalizedSegment,
} from 'modules/audiences/AudienceProvider';
import { CreateAudienceMutationVariables, UpdateAudienceMutationVariables } from 'types/schemaTypes';
import {
  areSegmentsEmpty,
  isSegmentEmpty,
  omitEmptyGroupsAndSubgroups,
} from 'modules/audiences/utils/areSegmentsEmpty';
import { DEFAULT_PROVIDER_ID } from '../hooks/useProviders';
import { omit } from 'lodash';

const { PREDEFINED_FIELD } = fields;

export const mapAudienceToCreateMutation = (
  account_id: string,
  user_id: string,
  state: State,
): CreateAudienceMutationVariables['object'] => {
  const name = getName(state);
  const description = getDescription(state);
  const export_type = getExportType(state) || ExportType.OA_MATCH;
  const direct_audience_provider = getDirectProvider(state) || DEFAULT_PROVIDER_ID;
  const normalized_definition = getNormalizedDefinition(state);
  const export_targets = getExportTargets(state);

  return {
    account_id,
    user_id,
    name,
    description,
    export_type,
    export_targets,
    direct_audience_provider,
    normalized_definition,
  };
};

export const mapAudienceToUpdateMutation = (state: State): UpdateAudienceMutationVariables['data'] => {
  const name = getName(state);
  const description = getDescription(state);
  const normalized_definition = getNormalizedDefinition(state);
  const direct_audience_provider = getDirectProvider(state) || DEFAULT_PROVIDER_ID;
  const export_targets = getExportTargets(state);
  const export_type = getExportType(state);

  return {
    name,
    description,
    export_targets,
    export_type,
    direct_audience_provider,
    normalized_definition,
  };
};

export const getNormalizedDefinition = (state: State): NormalizedDefinition => {
  const includesGroups = getIncludesGroups(state);
  const excludesGroups = getExcludesGroups(state);

  const filteredIncludesGroups = omitEmptyGroupsAndSubgroups(includesGroups);
  const filteredExcludesGroups = omitEmptyGroupsAndSubgroups(excludesGroups);

  const includesOperator = getIncludesMatchingOperator(state);

  const normalizedDefinition = {
    includes: {
      operator: includesOperator,
      groups: mapGroupsToND(filteredIncludesGroups),
    },
    excludes: {
      operator: MatchingOperator.UNION,
      groups: mapGroupsToND(filteredExcludesGroups),
    },
  };

  return normalizedDefinition;
};

// TODO: Refactor return type for mutation to omitted seg types
const mapSegmentsToND = (segments: Segments): NormalizedSegments => {
  const prepareSegments = (
    curValue: unknown,
    segments: Segments | NormalizedSegment[],
  ): Segments | NormalizedSegment[] => {
    if (curValue === PREDEFINED_FIELD) return Object.values(segments).reduce((acc, cur) => [...acc, ...cur], []);

    return segments;
  };

  const normalizedSegments = Object.keys(segments).reduce((acc, cur) => {
    if (isSegmentEmpty(cur, segments[cur])) return acc;

    const defSegment = {
      operator: MatchingOperator.UNION,
      segments: prepareSegments(cur, segments[cur]),
    };

    return {
      ...acc,
      [cur]: defSegment,
    };
  }, {});

  return {
    operator: MatchingOperator.INTERSECTION,
    ...transformFromDirectSegmentToDirect(normalizedSegments),
  };
};

const transformFromDirectSegmentToDirect = data => {
  const resultData = omit(data, 'direct_segment');
  resultData.direct = data.direct
    ? { ...data.direct, segments: [...(data.direct.segments ?? []), ...(data?.direct_segment?.segments ?? [])] }
    : data.direct_segment;
  return resultData;
};

const mapGroupsToND = (groups: GroupItem[]): (NormalizedGroup | NormalizedSegments)[] =>
  groups.map(({ segments, subgroups, operator }) =>
    areSegmentsEmpty(segments)
      ? {
          operator,
          groups: subgroups.map(({ segments }) => mapSegmentsToND(segments)),
        }
      : mapSegmentsToND(segments),
  );
