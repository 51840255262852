export const NAME_FIELD = 'name';
export const DESCRIPTION_FIELD = 'description';
export const EXPORT_TYPE_FIELD = 'export_type';
export const DIRECT_PROVIDER_FIELD = 'direct_audience_provider';
export const CTV_FIELD = 'ctv';
export const ACCOUNT_ID = 'account_id';
export const AUDIENCE_SHARES_FIELD = 'audience_shares';
export const RECIPIENTS_FIELD = 'recipients';
export const FEE_FIELD = 'fee';
export const INCLUDE_FIELD = 'include';
export const EXCLUDE_FIELD = 'exclude';
export const GROUPS_FIELD = 'groups';
export const SUBGROUPS_FIELD = 'subgroups';
export const OPEN_SUBGROUP_FIELD = 'open_subgroup';
export const OPERATOR_FIELD = 'operator';
export const SEGMENTS_FIELD = 'segments';
export const INDEX_FIELD = 'index';
export const GROUP_INDEX_FIELD = 'group_index';
export const SUBGROUP_INDEX_FIELD = 'subgroup_index';
export const PREDEFINED_FIELD = 'predefined';
export const IS_DRAWER_OPEN_FIELD = 'is_drawer_open';
export const RELATION_FIELD = 'relation';
export const LOCATION_FIELD = 'location';
export const DIRECT_SEGMENT_FIELD = 'direct_segment';
export const DIRECT_FIELD = 'direct';
export const RTG_FIELD = 'rtg';
export const THIRD_PARTY_FIELD = 'third_party';
export const SITE_CATEGORY_FIELD = 'site_category';
export const IS_STATE_SYNCED_FIELD = 'is_state_synced';
export const STATUS_FIELD = 'status';
export const UPDATED_DATE_FIELD = 'updated_date';
export const NORMALIZED_DEFINITION_FIELD = 'normalized_definition';
export const BULK_ACTION_FIELD = 'bulk_action';
export const EXPORT_TARGETS_FIELD = 'export_targets';

export const fields = {
  NAME_FIELD: 'name',
  ACCOUNT: 'account',
  DESCRIPTION_FIELD: 'description',
  EXPORT_TYPE_FIELD: 'export_type',
  DIRECT_PROVIDER_FIELD: 'direct_audience_provider',
  CTV_FIELD: 'ctv',
  ACCOUNT_ID_FIELD: 'account_id',
  AUDIENCE_SHARES_FIELD: 'audience_shares',
  RECIPIENTS_FIELD: 'recipients',
  FEE_FIELD: 'fee',
  INCLUDE_FIELD: 'include',
  EXCLUDE_FIELD: 'exclude',
  GROUPS_FIELD: 'groups',
  SUBGROUPS_FIELD: 'subgroups',
  OPEN_SUBGROUP_FIELD: 'open_subgroup',
  OPERATOR_FIELD: 'operator',
  SEGMENTS_FIELD: 'segments',
  INDEX_FIELD: 'index',
  GROUP_INDEX_FIELD: 'group_index',
  SUBGROUP_INDEX_FIELD: 'subgroup_index',
  DEMOGRAPHICS_FIELD: 'demographics',
  PREDEFINED_FIELD: 'predefined',
  IS_DRAWER_OPEN_FIELD: 'is_drawer_open',
  SELECTED_AGE: 'selected_age',
  RELATION_FIELD: 'relation',
  AGE_FIELD: 'demographic_age',
  GENDER_FIELD: 'demographic_gender',
  LOCATION_FIELD: 'location',
  DIRECT_SEGMENT_FIELD: 'direct_segment',
  DIRECT_FIELD: 'direct',
  RTG_FIELD: 'rtg',
  THIRD_PARTY_FIELD: 'third_party',
  SITE_CATEGORY_FIELD: 'site_category',
  IS_STATE_SYNCED_FIELD: 'is_state_synced',
  STATUS_FIELD: 'status',
  UPDATED_DATE_FIELD: 'updated_date',
  NORMALIZED_DEFINITION_FIELD: 'normalized_definition',
  HOUSEHOLD_INCOME_FIELD: 'household_income',
  BULK_ACTION_FIELD: 'bulk_action',
  LOCATION_CONGRESSIONAL_FIELD: 'congressional',
  PREDEFINED_THIRD_PARTY_FIELD: 'third_party',
  PREDEFINED_SITE_CATEGORY_FIELD: 'site_category',
  PREDEFINED_RTG_FIELD: 'rtg',
  AUDIENCE_INSIGHTS: 'audience_insights_data',
  TEMPLATE_NAME_FIELD: 'template_name',
  EXPORT_TARGETS_FIELD: 'export_targets',
} as const;

export default fields;
