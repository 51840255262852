import { ExportType } from 'modules/audiences/AudienceProvider';
import { Audience as QueryAudience, GetAudienceQuery, Provider, AudienceShare } from 'types/schemaTypes';
import { AudienceSharesByFee } from 'modules/audiences/components/AudienceShare/types';
import { canSetExportTargets } from './canSetExportTargets';
import { isEmpty } from 'lodash';

type Audience = Pick<
  QueryAudience,
  | 'id'
  | 'account'
  | 'name'
  | 'description'
  | 'normalized_definition'
  | 'status'
  | 'updated_date'
  | 'export_type'
  | 'export_targets'
  | 'direct_audience_provider'
  | 'audience_share'
> & { account_id?: string | null };

interface MapAudienceToProviderProps extends Audience {
  __typename?: string;
}

function prepareExportTargets(export_targets) {
  const isExportTargetsEmptyArray = Array.isArray(export_targets) && isEmpty(export_targets);

  if (export_targets === null || isExportTargetsEmptyArray) {
    return ['cookie', 'device', 'idl', 'ctv'];
  }
  const userExportTypeMapping = ['cookie', 'device', 'idl'];

  const targetsToFormat = [...export_targets];

  const idexOfUser = targetsToFormat.indexOf('user');

  if (idexOfUser > -1) {
    targetsToFormat.splice(idexOfUser, 1, ...userExportTypeMapping);
  }

  return targetsToFormat;
}

export function mapAudienceToProvider(
  data: GetAudienceQuery['audience_by_pk'],
  providers: Pick<Provider, 'id' | 'name' | 'allowed_export_types'>[],
) {
  const {
    account,
    normalized_definition,
    name,
    description,
    status,
    updated_date,
    export_type,
    export_targets,
    direct_audience_provider,
    audience_share = [],
    account_id,
  } = data as MapAudienceToProviderProps;

  const providerValues = providers.map(({ id }) => id);
  const exportType = (export_type || ExportType.OA_MATCH) as ExportType;

  const exportTargets = canSetExportTargets(exportType) ? prepareExportTargets(export_targets) : [];

  const audienceShares = groupAudienceSharesByFee(audience_share as AudienceShare[]);

  return {
    account,
    name,
    description,
    status,
    updated_date,
    export_type: exportType,
    direct_audience_provider,
    providerValues,
    export_targets: exportTargets,
    normalized_definition: normalized_definition,
    audience_shares: audienceShares,
    account_id: account_id,
  };
}

export const groupAudienceSharesByFee = (audienceShares: AudienceShare[] = []): AudienceSharesByFee[] => {
  const formatFee = (revenueMethod: string, pomFee: string, cpmFee: string): string =>
    revenueMethod === 'PoM' ? `${(parseFloat(pomFee) * 100).toFixed()}` : cpmFee;

  const audienceSharesByFee = audienceShares.reduce(
    (
      acc,
      {
        recipient_account_name: name,
        recipient_account_id: id,
        uid,
        revenue_method: revenueMethod,
        third_party_revenue: pomFee,
        cpm_cap: cpmFee,
        id: share_id,
      },
    ) => ({
      ...acc,
      [formatFee(revenueMethod as string, pomFee as string, cpmFee as string)]: acc[
        formatFee(revenueMethod as string, pomFee as string, cpmFee as string)
      ]
        ? {
            ...acc[formatFee(revenueMethod as string, pomFee as string, cpmFee as string)],
            recipiens: [
              ...acc[formatFee(revenueMethod as string, pomFee as string, cpmFee as string)].recipiens,
              { id, name, uid },
            ],
            revenueMethod,
            share_id,
            uids: [...acc[formatFee(revenueMethod as string, pomFee as string, cpmFee as string)].uids, uid],
          }
        : { recipiens: [{ id, name, uid }], revenueMethod, share_id, uids: [uid] },
    }),
    {},
  );

  return Object.keys(audienceSharesByFee).map(fee => ({
    recipients: audienceSharesByFee[fee].recipiens,
    fee,
    revenueMethod: audienceSharesByFee[fee].revenueMethod,
    shareId: audienceSharesByFee[fee].share_id,
    uids: audienceSharesByFee[fee].uids,
  }));
};

export function getDirectSegments(segments = [], providerValues) {
  return segments.filter(({ sub_type }) => providerValues.includes(sub_type));
}

export function omitDirectSegments(segments = [], providerValues) {
  return segments.filter(({ sub_type }) => !providerValues.includes(sub_type));
}
