import { TreeData, EnhancedTreeData } from 'utils';
import { AudienceRow } from '../AudiencesList';

import fields from './fields';
import { TRevenueMethod } from '../components';

//TODO: Replace Enums
export enum ActionType {
  ADD_GROUP = 'ADD_GROUP',
  ADD_SUBGROUP = 'ADD_SUBGROUP',
  REMOVE_GROUP = 'REMOVE_GROUP',
  REMOVE_SUBGROUP = 'REMOVE_SUBGROUP',
  SET_INCLUDES_OPERATOR = 'SET_INCLUDES_OPERATOR',
  SET_GROUP_OPERATOR = 'SET_GROUP_OPERATOR',
  SET_OPEN_SUBGROUP = 'SET_OPEN_SUBGROUP',
  SAVE_SEGMENT = 'SAVE_SEGMENT',
  SET_IS_DRAWER_OPEN = 'SET_IS_DRAWER_OPEN',
  SET_SELECTED_AGE = 'SET_SELECTED_AGE',
  SET_LOCATION = 'SET_LOCATION',
  SET_PREDEFINED = 'SET_PREDEFINED',
  SET_RTG = 'SET_RTG',
  SET_THIRD_PARTY = 'SET_THIRD_PARTY',
  SET_SITE_CATEGORY = 'SET_SITE_CATEGORY',
  SET_DEMOGRAPHIC_AGE = 'SET_DEMOGRAPHIC_AGE',
  SET_DEMOGRAPHIC_GENDER = 'SET_DEMOGRAPHIC_GENDER',
  SET_DIRECT_SEGMENT = 'SET_DIRECT_SEGMENT',
  SET_DIRECT_PROVIDER = 'SET_DIRECT_PROVIDER',
  SET_EXPORT_TYPE = 'SET_EXPORT_TYPE',
  SET_CTV = 'SET_CTV',
  SET_DESCRIPTION = 'SET_DESCRIPTION',
  SET_ACCOUNT_ID = 'SET_ACCOUNT_ID',
  SET_NAME = 'SET_NAME',
  RESET_AUDIENCE = 'RESET_AUDIENCE',
  SET_FIRST_PARTY = 'SET_FIRST_PARTY',
  SET_AUDIENCE = 'SET_AUDIENCE',
  SET_AUDIENCE_INSIGHTS = 'SET_AUDIENCE_INSIGHTS',
  SET_AUDIENCE_SHARES_FIELD = 'SET_AUDIENCE_SHARES_FIELD',
  REMOVE_DEFAULT_AUDIENCE_SHARE = 'REMOVE_DEFAULT_AUDIENCE_SHARE',
  ADD_DEFAULT_AUDIENCE_SHARE = 'ADD_DEFAULT_AUDIENCE_SHARE',
  SET_AUDIENCE_SHARE_FEE = 'SET_AUDIENCE_SHARE_FEE', // TODO:
  SET_AUDIENCE_SHARE_REVENUE_METHOD = 'SET_AUDIENCE_SHARE_REVENUE_METHOD',
  REMOVE_AUDIENCE_SHARE_ROW = 'REMOVE_AUDIENCE_SHARE_ROW',
  SET_AUDIENCE_SHARE_RECIPIENTS = 'SET_AUDIENCE_SHARE_RECIPIENTS',
  SET_BULK_ACTION_FIELD = 'SET_BULK_ACTION_FIELD',
  RESET_BULK_ACTION_FIELD = 'RESET_BULK_ACTION_FIELD',
  SET_TEMPLATE_NAME = 'SET_TEMPLATE_NAME',
  SET_EXPORT_TARGETS = 'SET_EXPORT_TARGETS',
}

export type ActionPayload = {
  groupIndex?: number;
  subgroupIndex?: number;
  operator?: MatchingOperator;
  segments?: Segments;
  relationField?: RelationType;
  isDrawerOpen?: boolean;
  selectedAge?: number;
  directProvider?: string;
  ageV1?: TreeData[];
  genderV1?: TreeData[];
  exportType?: ExportType;
  ctv?: boolean;
  name?: string;
  audienceShares?: AudienceShare[];
  audienceShareFee?: string | null; // TODO:
  audienceShareRevenueMethod?: TRevenueMethod;
  audienceShareIndex?: number;
  audienceShareRecipients?: Recipient[];
  description?: string | null;
  account_id?: string | null;
  firstParty?: EnhancedTreeData[];
  normalized_definition?: any;
  providerValues?: any;
  bulkAction?: BulkOption;
  templateName?: string;
  exportTargets?: string[];
};

export type Action = {
  type: ActionType;
  payload?: ActionPayload;
};

interface Recipient {
  id: string;
  uid?: string;
  name: string;
}

// TODO:
export type AudienceShare = {
  recipients: Recipient[];
  fee?: string | null;
  currency?: string | null;
  revenueMethod?: string | null;
  shareId?: string | null;
};

export interface NormalizedSegment {
  id: string;
  external_id: string;
}

export type AgeType = {
  min?: number;
  max?: number;
  caption?: string;
};

//TODO: Replace Enums
export enum PredefinedSegmentType {
  RTG_FIELD = 'rtg',
  THIRD_PARTY_FIELD = 'third_party',
  SITE_CATEGORY_FIELD = 'site_category',
}

//TODO: Replace Enums
export enum ExportType {
  // OA_MATCH is DEFAULT
  OA_MATCH = 'oa_match',
  BIDSTREAM = 'bidstream',
  BIDSTREAM_PLUS = 'bidstream_plus',
  LEGACY_OA_MATCH_INTERSECTION = 'oa_match',
  LEGACY_OA_MATCH_UNION = 'oa_match',
  LEGACY_PROVIDER_EDGE_UNION = 'bidstream_plus',
  LEGACY_PASSTHROUGH = 'bidstream',
}

export const LegacyToNewExportType = {
  [ExportType.LEGACY_OA_MATCH_INTERSECTION]: ExportType.OA_MATCH,
  [ExportType.LEGACY_OA_MATCH_UNION]: ExportType.OA_MATCH,
  [ExportType.LEGACY_PASSTHROUGH]: ExportType.BIDSTREAM,
  [ExportType.LEGACY_PROVIDER_EDGE_UNION]: ExportType.BIDSTREAM_PLUS,
};

//TODO: Replace Enums
export enum ReportStatus {
  COMPLETE = 'complete',
  PROCESSING = 'processing',
  ERROR = 'error',
  SF_COMPLETE = 'sf_account_complete',
  SF_PROCESSING = 'sf_account_processing',
  SF_ERROR = 'sf_account_error',
}

export const ExportTarget = {
  COOKIE: 'cookie',
  MAIDS: 'device',
  RAMP_ID: 'idl',
  CTV: 'ctv',
  IP_ADDRESS: 'ip',
} as const;

//TODO: Replace Enums
export enum MatchingOperator {
  INTERSECTION = 'INTERSECT',
  UNION = 'UNION',
}

//TODO: Replace Enums
export enum RelationType {
  INCLUDE = 'include',
  EXCLUDE = 'exclude',
}

//TODO: Replace Enums
export enum LocationType {
  STATE = 'state',
  CONGRESSIONAL = 'congressional',
  DMA = 'dma',
  ZIP = 'zip',
}

//TODO: Replace Enums
export enum HighPrecisionProvider {
  MERKLE = 'merkle',
  CHOREOGRAPH = 'choreograph',
  EPSILON = 'epsilon',
  ANALYTICSIQ = 'analyticsiq',
}

//TODO: Replace Enums
export enum ErrorCodes {
  SEGMENT_EXPIRED_OR_MISSING = 'SEGMENT_EXPIRED_OR_MISSING',
  INVALID_AUDIENCE_NO_INCLUDES = 'INVALID_AUDIENCE_NO_INCLUDES',
  RATE_LIMIT_EXCEEDED = 'RATE_LIMIT_EXCEEDED',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
}

export type Demographics = {
  [fields.AGE_FIELD]?: EnhancedTreeData[];
  [fields.GENDER_FIELD]?: EnhancedTreeData[];
};

export type Predefined = {
  [fields.RTG_FIELD]: EnhancedTreeData[];
  [fields.THIRD_PARTY_FIELD]: EnhancedTreeData[];
  [fields.SITE_CATEGORY_FIELD]: EnhancedTreeData[];
};

export type Segments = Demographics & {
  [fields.LOCATION_FIELD]?: EnhancedTreeData[];
  [fields.DIRECT_FIELD]?: EnhancedTreeData[];
  [fields.DIRECT_SEGMENT_FIELD]?: EnhancedTreeData[];
  [fields.PREDEFINED_FIELD]?: Predefined;
};

export type SubgroupItem = {
  [fields.INDEX_FIELD]: number;
  [fields.SEGMENTS_FIELD]: Segments;
};

export type GroupItem = {
  [fields.OPERATOR_FIELD]: MatchingOperator;
  [fields.INDEX_FIELD]: number;
  [fields.SUBGROUPS_FIELD]: SubgroupItem[];
  [fields.SEGMENTS_FIELD]: Segments;
};

export type OpenSubgroup = {
  [fields.SEGMENTS_FIELD]: Segments;
  [fields.GROUP_INDEX_FIELD]: number;
  [fields.SUBGROUP_INDEX_FIELD]: number;
  [fields.RELATION_FIELD]: RelationType;
};

export type NormalizedDefinitionItem = {
  [fields.OPERATOR_FIELD]?: MatchingOperator;
  [fields.GROUPS_FIELD]: GroupItem[];
};

export type BulkOption = {
  label: string;
  value: string;
  function: (audiences: AudienceRow[]) => void;
};

export interface IncludesSegment {
  operator: MatchingOperator;
  includes: NormalizedSegment[];
}

export interface ExcludesSegment {
  operator: MatchingOperator;
  excludes: NormalizedSegment[];
}

export type Segment = IncludesSegment | ExcludesSegment;

export interface NormalizedSegments {
  operator: MatchingOperator;
  location?: Segment;
  first_party?: Segment;
  age_field?: Segment;
  gender_field?: Segment;
  predefined?: Segment;
  direct_segment?: Segment;
}

export interface NormalizedGroup {
  operator: MatchingOperator;
  items: NormalizedItem;
}

export interface NormalizedItem {
  operator: MatchingOperator;
  groups: (NormalizedGroup | NormalizedSegments)[];
}

export interface NormalizedDefinition {
  includes: NormalizedItem;
  excludes: NormalizedItem;
}
